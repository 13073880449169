import { connect } from 'react-redux';

import {
    resumeTrainer,
    addSnoozeTimer,
    setInfinitySnooze
} from '../../store/actions/trainer';
import InactiveTrainer from '../../components/InactiveTrainer';
import withLocalization from '../../localization/withLocalization';

const mapStateToProps = store => ({
    snoozeTimer: parseInt(store.trainer.snoozeTimer, 10) || 0,
    errorCode: store.trainer.errorCode
});

export default connect(mapStateToProps, {
    resumeTrainer,
    addSnoozeTimer,
    setInfinitySnooze
})(withLocalization(InactiveTrainer));
