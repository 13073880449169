import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../utils';

const Wrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    display: flex;

    align-content: center;
    align-items: center;

    width: 100%;
    height: 40px;

    padding: 0px 18px;

    background-color: ${colors.white.basic};
    box-shadow: ${props =>
        props.$showBoxShadow ? '0 3px 3px 0 rgba(0, 0, 0, 0.03)' : ''};

    & > div {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    & > :first-child {
        flex: 1;
        justify-content: flex-start;
    }

    & > div:first-child {
        height: 22px;
    }

    & > :last-child {
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        line-height: 1;
    }
`;

Wrapper.propTypes = {
    $showBoxShadow: PropTypes.bool.isRequired
};

export default Wrapper;
