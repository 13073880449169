import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import Error from '../icons/Error';
import Suggestion from './styled/Suggestion';
import CloseButton from '../CloseButton/CloseButton';
import Wrapper from './styled/Wrapper';
import Header from './styled/Header';
import Assessment from '../icons/Assessment';
import Cross from '../icons/Cross';
import Check from '../icons/Check';
import { colors } from '../../../utils';
import { hyphenate } from '../../../utils/hyphenopoly';

function CallSuggestions({
    suggestions,
    suggestionsTimestamp,
    suggestionCallFrequency,
    callSuggestionsOpen,
    closeCallSuggestions,
    localization
}) {
    if (!callSuggestionsOpen || suggestions.length === 0) {
        return null;
    }

    const renderMessage = (message, word) => {
        if (message.indexOf('[[word]]') > -1) {
            const parts = message.split('[[word]]');
            return (
                <span>
                    {parts[0]} <b>{hyphenate[localization.currentLocale](word)}</b>{' '}
                    {parts[1]}
                </span>
            );
        }

        return message;
    };

    return (
        <Wrapper>
            <Header>
                <Assessment size={40} color="#fff" />
                <div>
                    <h6>{localization.getText('callSuggestions.title')}</h6>
                    <p>
                        {localization.getText('callSuggestions.numberOfCalls', {
                            calls: suggestionCallFrequency
                        })}
                        {` - `}
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {localization.getText('callSuggestions.lastUpdate')}:{' '}
                            {format(suggestionsTimestamp, 'HH:mm')}
                        </span>
                    </p>
                </div>
                <CloseButton onClick={() => closeCallSuggestions()}>
                    <Cross size={16} color="#fff" />
                </CloseButton>
            </Header>
            {suggestions.map(suggestion => {
                return (
                    <Suggestion
                        key={suggestion.message}
                        $isPositive={suggestion.positive}>
                        <div>
                            {suggestion.positive ? (
                                <Check size={18} color={colors.green.envy} />
                            ) : (
                                <Error size={20} color={colors.red.radical_red} />
                            )}
                        </div>
                        <div className="msg">
                            {renderMessage(suggestion.message, suggestion.keyword)}
                        </div>
                    </Suggestion>
                );
            })}
        </Wrapper>
    );
}

CallSuggestions.propTypes = {
    suggestions: PropTypes.array,
    suggestionCallFrequency: PropTypes.number,
    suggestionsTimestamp: PropTypes.string,
    callSuggestionsOpen: PropTypes.bool.isRequired,
    closeCallSuggestions: PropTypes.func.isRequired,
    localization: PropTypes.object.isRequired
};

CallSuggestions.defaultProps = {
    suggestions: [],
    suggestionsTimestamp: '',
    suggestionCallFrequency: 0
};

export default CallSuggestions;
