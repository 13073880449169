// noinspection JSUnusedLocalSymbols

import semver from 'semver';
import {proto, types} from '../../services/ListenerProto';
import {loginWithCompany, types as authTypes,} from '../actions/auth';
import {
    daemonVersionReceived,
    deviceListReceived,
    deviceListReceiveError,
    mainConnectionClosed,
    mainConnectionOpened,
    startAudioDeviceConnection,
    startAudioSystemInfoConnection,
    startEventForwardingConnection,
    stopMainConnection,
    types as mainTypes
} from '../actions/main';
import MainConnection from '../../services/MainConnection';
import {filterDefaultDevices, semverDiff} from '../../utils';
import {reload, types as commonTypes} from '../actions/common';
import {isMainConnectionOpen} from '../selectors';
import {captureException} from '../../../tracker/raven';
import {audioSysLogger, mainConnLogger as logger} from '../../../utils/logger';
import {errorCodes} from '../../../utils';

import {Common, MainConn} from '../../errors';
import {getListenerRequestError, getRequestType} from '../../utils/error';
import {DAEMON_VERSION, wsConnectionClosingCodes} from '../../constants';
import {IS_LEGACY_LISTENER_MODE} from "../../../config/electron";

const {
    UNEXPECTED_DAEMON_VERSION,
    MAIN_CONNECTION_CLOSED,
    HARDWARE_ERROR,
    MAIN_CONNECTION_CLOSED_ON_START
} = errorCodes.background;

const { INTERNAL_ERROR, INVALID_REQUEST } = proto.Status;

const ErrorTypes = {
    [INTERNAL_ERROR]: MainConn.InternalError,
    [INVALID_REQUEST]: MainConn.InvalidRequestError
};

function createListenerMainConnection(dispatch) {
    // TODO: move main connection port to config
    const connection = new MainConnection({ host: 'localhost', port: 51873 });
    connection.on('opened', () => {
        dispatch(mainConnectionOpened());
    });
    connection.on('closed', ({ wasClean, code, reason }) => {
        if (wasClean) {
            logger.log('closed gracefully');
        } else {
            logger.error(`closed with error, code: ${code}, reason: ${reason}`);
        }
        dispatch(mainConnectionClosed(wasClean, code));
    });
    connection.on('message', data => {
        const message = proto.Response.decode(data);
        switch (message.responseType) {
            case types.AUDIOSYSTEM_NOTIFICATOR_ENDPOINT: {
                const { port } = message.audiosystemNotificatorEndpoint;
                dispatch(startAudioSystemInfoConnection(port));
                break;
            }
            case types.AUTHORIZATION_PROMPT: {
                const sysInfo = message.authorizationPrompt.systemInformation;
                dispatch({ type: authTypes.SYSTEM_INFO_RECEIVED, payload: { systemInfo: sysInfo } });
                break;
            }
            case types.SYSTEM_INFORMATION: {
                dispatch({ type: authTypes.SYSTEM_INFO_UPDATED, payload: { systemInfo: message.systemInformation } });
                break;
            }
            case types.SERVICE_VERSION: {
                const { major, minor, patch, hasOpus } = message.serviceVersion;
                window.hasOpus = !!hasOpus;
                const version = `${major}.${minor}.${patch}`;
                dispatch(daemonVersionReceived(version));
                break;
            }
            case types.ACTIVE_AUDIO_DEVICES: {
                const { audioDevices } = message.activeAudioDevices;
                const defaultDevices = filterDefaultDevices(audioDevices);
                const { mics, speakers } = defaultDevices;
                // TODO: check the absence of mic or speaker or both
                dispatch(deviceListReceived(mics, speakers));
                break;
            }
            case types.DEVICE_ENDPOINT: {
                const { port } = message.deviceEndpoint;
                dispatch(startAudioDeviceConnection(port));
                break;
            }
            case types.EVENT_BROADCASTER_ENDPOINT: {
                const { port } = message.eventBroadcasterEndpoint;
                dispatch(startEventForwardingConnection(port));
                break;
            }
            case types.SERVICE_MESSAGE: {
                const { status } = message.serviceMessage;
                const serviceMessage = message.serviceMessage.toJSON();
                logger.log('Service message', serviceMessage);

                if (status === INTERNAL_ERROR || status === INVALID_REQUEST) {
                    const extra = { debugInfo: serviceMessage };
                    const { metainfo = [], initialRequest = {} } = message.serviceMessage;
                    const requestType = getRequestType(initialRequest);
                    let error = null;

                    if (!requestType) {
                        // Default type in case serviceMessage does not have a request type
                        error = new ErrorTypes[status]({ status, extra, metainfo });
                    } else {
                        error = getListenerRequestError(
                            status,
                            requestType,
                            metainfo,
                            extra
                        );
                    }

                    captureException(error, null);
                    logger.error(error.message, error);
                }

                if (status === INTERNAL_ERROR) {
                    dispatch(reload({ code: HARDWARE_ERROR, reason: 'HARDWARE_ERROR' }));
                }
                break;
            }
            default:
                logger.warn('Unsupported message type', message);
        }
    });
    return connection;
}

export default store => {
    const { dispatch, getState } = store;
    const connection = createListenerMainConnection(dispatch);

    return next => action => {
        const { type, payload } = action;
        const isOpen = isMainConnectionOpen(getState());
        const result = next(action);
        const state = getState();

        switch (type) {
            case commonTypes.RELOAD: {
                if (isMainConnectionOpen(state)) {
                    dispatch(stopMainConnection());
                }
                break;
            }
            case mainTypes.START_MAIN_CONNECTION: {
                if (!IS_LEGACY_LISTENER_MODE) {
                    break;
                }

                if (!isMainConnectionOpen(state)) {
                    connection.start();
                } else {
                    const error = new MainConn.AlreadyStartedError();
                    captureException(error);
                    logger.error(error.message);
                }
                break;
            }
            case commonTypes.CLOSE_ALL_CONNECTIONS:
            case mainTypes.STOP_MAIN_CONNECTION: {
                if (isMainConnectionOpen(state)) {
                    // Cases when closing the connection without reload OR with reload
                    const code = !payload
                        ? wsConnectionClosingCodes.DEFAULT
                        : payload.code;
                    connection.finishConnection(code);
                }
                break;
            }
            case authTypes.SYSTEM_INFO_RECEIVED: {
                const { companyName, username, companySecretToken, uniqueId } = payload.systemInfo
                if(!state.auth.token){
                    dispatch(loginWithCompany(companyName, username, companySecretToken, uniqueId));
                }
                break;
            }
            case mainTypes.INIT_LISTENER: {
                connection.sendAuthorizationRequest(state.user.profile.secret);
                connection.sendRefreshTokenRequest(state.auth.token);
                connection.sendServiceVersionRequest();
                connection.sendEventBroadcasterRequest();
                break;
            }
            case authTypes.TOKEN_REFRESH_SUCCESS: {
                connection.sendRefreshTokenRequest(payload.token);
                break;
            }
            case mainTypes.REQUEST_DEVICE_LIST:
                connection.sendDeviceListRequest();
                break;
            case mainTypes.DEVICE_LIST_RECEIVED: {
                const { mics, speakers } = payload;

                if (mics.length > 0 && speakers.length > 0) {
                    connection.sendStartMixedRecordingRequest([...mics, ...speakers]);
                    break;
                }
                if (mics.length === 0) {
                    const micError = new Common.MicDeviceNotFoundError();
                    captureException(micError);
                    audioSysLogger.error(micError.message, micError);
                    dispatch(deviceListReceiveError());
                }
                if (speakers.length === 0) {
                    const speakerError = new Common.SpeakerDeviceNotFoundError();
                    captureException(speakerError);
                    audioSysLogger.error(speakerError.message, speakerError);
                    dispatch(deviceListReceiveError());
                }
                break;
            }
            case mainTypes.GET_SYSTEM_INFORMATION: {
                connection.sendSystemInformationRequest();
                break;
            }
            case mainTypes.DAEMON_VERSION_RECEIVED: {
                const actual = payload.version;
                const expected = DAEMON_VERSION;
                const diff = semverDiff(actual, expected);

                if (semver.eq(actual, expected)) {
                    break;
                }

                if (diff !== 'major') {
                    logger.warn(
                        `Daemon version patch mismatch. Expected: ${expected}, received: ${actual}`
                    );
                } else {
                    logger.error(
                        `Daemon version mismatch. Expected: ${expected}, received: ${actual}`
                    );

                    dispatch(
                        reload({
                            code: UNEXPECTED_DAEMON_VERSION,
                            reason: 'UNEXPECTED_DAEMON_VERSION'
                        })
                    );
                }
                break;
            }
            case mainTypes.MAIN_CONNECTION_CLOSED: {
                const { code = null } = payload;

                // TODO: add tracking
                if (code === wsConnectionClosingCodes.NO_RELOAD) return;

                if (isOpen) {
                    dispatch(
                        reload({
                            code: MAIN_CONNECTION_CLOSED,
                            reason: 'MAIN_CONNECTION_CLOSED'
                        })
                    );
                } else {
                    dispatch(
                        reload({
                            code: MAIN_CONNECTION_CLOSED_ON_START,
                            reason: 'MAIN_CONNECTION_CLOSED_ON_START'
                        })
                    );
                }

                break;
            }

            default: // Do nothing
        }

        return result;
    };
};
