// @flow
import { max, every, isNumber } from 'lodash';

import {
    SET_BLACKLIST,
    SET_WHITELIST,
    SET_SPEECH_RATE,
    SET_SPEECH_RATIO,
    SET_LOUDNESS,
    SET_IS_STARTED,
    SET_ELAPSED_SECONDS,
    SET_CALL_RESULT,
    SET_RESULT_POPUP_VISIBILITY,
    SET_RESULT_POPUP_VISIBILITY_ON_LOAD,
    RELOAD,
    UPDATE_RELOAD_COUNT_DOWN,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_USER_INTERFACE_CONFIG,
    HIDE_NEW_CALL_MESSAGE,
    SET_MANUAL_BUTTON_AVAILABILITY,
    SET_SNOOZE_TIMER_SUCCESS,
    SET_SNOOZE_TIMER_FAIL,
    SNOOZE_TRAINER,
    ADD_SNOOZE_TIMER,
    DEVICE_LIST_RECEIVE_ERROR,
    CONFIGURATION_LOADED,
    SET_ASR_CONFIG_ERRORS,
    MANUAL_OPT_IN,
    MANUAL_OPT_OUT,
    MANUAL_OPT_STOP,
    CALL_HOLD,
    CALL_UNHOLD,
    SET_INFINITY_SNOOZE,
    RESUME_TRAINER,
    SEND_MANUAL_CALL_EVENT,
    DEVICE_LIST_RECEIVED,
    SET_SPEECH_PACE,
    SET_NEW_SPEECH_RATE,
    SAVE_CONVERSION,
    SET_RECORD_CALL,
    EVENT_OPT_IN,
    EVENT_OPT_OUT,
    EVENT_OPT_STOP,
    OPT_RESET,
    CALL_NOTIFICATION,
    SET_PHRASE_INTEGRATION_WORDS,
} from '../actions/actionTypes/trainer';

import { GET_USER_PROFILE_FAIL, SET_USER_SCORES_SUCCESS } from '../actions/actionTypes/user';
import {RECEIVE_CRX_CALL_STARTED, RECEIVE_DAEMON_VERSION} from '../actions/actionTypes/CRX';
import { calculateScore } from '../../utils/points';
import errorCodes from '../../utils/errorCodes';
import { compareVersions } from '../../utils/versions';

import {setSnoozeTimer, types as snoozeTypes} from "../../background/store/actions/snooze";
import {INFINITY_SNOOZE_SEC} from "../../background/constants";
import {types} from "../../background/store/actions/call";
import {HIDE_CONVERSION_MODAL, SHOW_CONVERSION_MODAL} from "../actions/actionTypes/conversion";

type Action = {
    type: string,
    payload: Object
};

type State = {|
    daemonVersion: string,
    configLoaded: boolean,
    whitelist: Array<Object>,
    blacklist: Array<Object>,
    speechRate: number | null,
    newSpeechRate: number | null,
    speechPace: number | null,
    speechRatio: number | null,
    loudness: number | null,
    isStarted: boolean,
    elapsedSeconds: number | null,
    whitelistPhrasesSpottingScore: Object | null,
    lastCallPhraseSpottingScore: Object | null,
    isCallResultVisible: boolean,
    isLastCallResult: boolean,
    isReloading: boolean,
    isSoundContinued: boolean,
    errorCode: number | null,
    countdown: number,
    isNotificationShown: boolean,
    systemSoundRecognition: boolean,
    enableTrainer: boolean,
    enableSales: boolean,
    userInterfaceConfig: Object,
    bonusScore: number,
    showNewCallMessage: boolean,
    showCallEndMessage: boolean,
    showTranscript: true,
    silenceDetectionThreshold: number,
    suggestionCallFrequency: number,
    isTrainerActive: boolean,
    isManualCallButtonEnabled: boolean,
    snoozeTimer: number | null,
    deviceListError: boolean,
    asrConfigErrors: Object,
    optInOn: boolean,
    isOptOut: boolean,
    stopRecordingCustomer: boolean,
    callHold: boolean,
    deviceList: Object,
    isCallEnded: boolean,
    callDuration: number,
    callId: string | null,
    recordCall: boolean,
    conversionModalOpen: boolean,
    callEventNotification: string | null,
    keyPhraseIntegrationWords: string[] | null,
|};

export const initialState: State = {
    daemonVersion: '',
    configLoaded: false,
    whitelist: [],
    blacklist: [],
    speechRate: null,
    newSpeechRate: null,
    speechPace: null,
    speechRatio: null,
    loudness: null,
    isStarted: false,
    elapsedSeconds: 0,
    showNewCallMessage: false,
    showCallEndMessage: false,
    whitelistPhrasesSpottingScore: null,
    lastCallPhraseSpottingScore: null,
    isCallResultVisible: false,
    isLastCallResult: false,
    isSoundContinued: false,
    isReloading: false,
    errorCode: null,
    countdown: 0,
    isNotificationShown: false,
    systemSoundRecognition: false,
    showTranscript: true,
    userInterfaceConfig: {
        displayTranscript: false,
        requirePersonalUsername: false,
        displaySnoozingControls: false,
        displayCallOptOutControls: false,
        showDashboardLink: false,
        moveSaidDoSayWordsToBottom: false,
        displayGamificationPerCall: true,
        displayGamificationWeekly: true,
        displayRecordingState: false,
        displayNewSpeechRate: false,
    },
    enableTrainer: false,
    enableSales: false,
    bonusScore: 0,
    silenceDetectionThreshold: 0,
    suggestionCallFrequency: 0,
    isTrainerActive: true,
    isManualCallButtonEnabled: true,
    snoozeTimer: null,
    deviceListError: false,
    asrConfigErrors: {},
    optInOn: true,
    isOptOut: false,
    stopRecordingCustomer: false,
    callHold: false,
    deviceList: {
        mics: [],
        speakers: []
    },
    isCallEnded: false,
    callDuration: 0,
    callId: null,
    recordCall: true,
    conversionModalOpen: false,
    callEventNotification: null,
    keyPhraseIntegrationWords: null,
};

const roundToParticularSign = (value, signs = 0) => {
    return isNumber(value) ? Math.round(value * 10 ** signs) / 10 ** signs : null;
};

const getWhitelistScore = (whitelist, bonusScore) => {
    const totalScore = whitelist.reduce((acc, item) => {
        return acc + (max(item.alternatives.map(alt => alt.points)) || 0);
    }, 0);

    return {
        spottedPhrasesScore: calculateScore(whitelist),
        totalPhrasesScore: totalScore,
        bonusScore: every(whitelist, 'count') ? bonusScore : 0
    };
};

const updateScore = (state, newWhitelist) => {
    if (state.isStarted || !state.whitelistPhrasesSpottingScore) {
        return getWhitelistScore(newWhitelist, state.bonusScore);
    }
    return state.whitelistPhrasesSpottingScore;
};

const clearScore = scores => {
    return {
        ...scores,
        bonusScore: 0,
        spottedPhrasesScore: 0
    };
};

// eslint-disable-next-line default-param-last
const trainer = (state: State = initialState, action: Action): State => {
    const { type } = action;
    switch (type) {
        case CONFIGURATION_LOADED: {
            return {
                ...state,
                configLoaded: true
            };
        }

        case SET_ASR_CONFIG_ERRORS: {
            return {
                ...state,
                asrConfigErrors: action.payload.asrConfigErrors
            };
        }
        case GET_USER_PROFILE_FAIL: {
            return initialState;
        }
        case SET_IS_STARTED: {
            const { isStarted, systemSoundRecognition } = action.payload;

            return {
                ...state,
                isStarted,
                isSoundContinued: false,
                showNewCallMessage: isStarted,
                showCallEndMessage: !isStarted,
                systemSoundRecognition: isStarted
                    ? systemSoundRecognition
                    : state.systemSoundRecognition,
                whitelistPhrasesSpottingScore: isStarted
                    ? clearScore(state.whitelistPhrasesSpottingScore)
                    : state.whitelistPhrasesSpottingScore,
                optInOn: isStarted ? false : state.optInOn,
                isOptOut: false,
                callHold: false
            };
        }

        case SHOW_CONVERSION_MODAL: {
            return { ...state, conversionModalOpen: true };
        }

        case HIDE_CONVERSION_MODAL: {
            return { ...state, conversionModalOpen: false };
        }

        case HIDE_NEW_CALL_MESSAGE: {
            return { ...state, showNewCallMessage: false };
        }

        case SET_ELAPSED_SECONDS: {
            return { ...state, elapsedSeconds: action.payload.elapsedSeconds };
        }
        case SET_LOUDNESS: {
            return { ...state, loudness: roundToParticularSign(action.payload.loudness) };
        }
        case SET_SPEECH_RATIO: {
            return {
                ...state,
                speechRatio: roundToParticularSign(action.payload.speechRatio, 1)
            };
        }
        case SET_SPEECH_PACE: {
            return {
                ...state,
                speechPace: roundToParticularSign(action.payload.speechPace)
            };
        }
        case SET_NEW_SPEECH_RATE: {
            return {
                ...state,
                newSpeechRate: roundToParticularSign(action.payload.newSpeechRate)
            };
        }
        case SET_SPEECH_RATE: {
            return {
                ...state,
                speechRate: roundToParticularSign(action.payload.speechRate)
            };
        }
        case SET_WHITELIST: {
            const { whitelist } = action.payload;

            return {
                ...state,
                whitelist: action.payload.whitelist,
                whitelistPhrasesSpottingScore: updateScore(state, whitelist)
            };
        }
        case SET_BLACKLIST: {
            return { ...state, blacklist: action.payload.blacklist };
        }
        case SET_CALL_RESULT: {
            return {
                ...state,
                whitelist: action.payload.whitelist,
                lastCallPhraseSpottingScore: {
                    ...state.whitelistPhrasesSpottingScore
                },
                isLastCallResult: action.payload.isLastCallResult,
                isCallResultVisible: state.userInterfaceConfig.displayGamificationPerCall
            };
        }
        case SET_USER_SCORES_SUCCESS: {
            return {
                ...state,
                whitelistPhrasesSpottingScore: clearScore(
                    state.whitelistPhrasesSpottingScore
                )
            };
        }
        case SET_PHRASE_INTEGRATION_WORDS: {
            return {
                ...state,
                keyPhraseIntegrationWords: action.payload.keyPhraseIntegrationWords
            };
        }
        case SET_RESULT_POPUP_VISIBILITY: {
            return { ...state, isCallResultVisible: action.payload.isCallResultVisible };
        }
        case SET_RESULT_POPUP_VISIBILITY_ON_LOAD: {
            return {
                ...state,
                isCallResultVisible: state.isCallResultVisible && state.isLastCallResult && state.recordCall
            };
        }
        case RELOAD: {
            if(!action.payload.isImmediate){
                return {
                    ...state,
                    isReloading: true,
                    countdown: action.payload.countdown,
                    errorCode: action.payload.code
                };
            }

            return state;
        }
        case UPDATE_RELOAD_COUNT_DOWN: {
            return { ...state, countdown: action.payload.countdown };
        }

        case SHOW_NOTIFICATION: {
            return { ...state, isNotificationShown: true };
        }

        case HIDE_NOTIFICATION: {
            return { ...state, isNotificationShown: false };
        }
        case SET_USER_INTERFACE_CONFIG: {
            const { payload } = action;
            return {
                ...state,
                userInterfaceConfig: {
                    ...state.userInterfaceConfig,
                    ...payload.userInterfaceConfig
                },
                bonusScore: payload.bonusScore,
                silenceDetectionThreshold: payload.silenceDetectionThreshold,
                suggestionCallFrequency: payload.suggestionCallFrequency,
                enableTrainer: payload.enableTrainer,
                enableSales: payload.enableSales,
            };
        }
        case RECEIVE_DAEMON_VERSION: {
            const { daemonVersion } = action.payload;
            const { showDashboardLink } = state.userInterfaceConfig;
            const isAvailableVersion = compareVersions(daemonVersion, '1.8.66');
            return {
                ...state,
                daemonVersion,
                userInterfaceConfig: {
                    ...state.userInterfaceConfig,
                    showDashboardLink: showDashboardLink && isAvailableVersion
                }
            };
        }
        case SET_SNOOZE_TIMER_SUCCESS: {
            const { payload } = action;
            return {
                ...state,
                isTrainerActive: false,
                snoozeTimer: payload.snoozeFor
            };
        }
        case SET_SNOOZE_TIMER_FAIL: {
            return {
                ...state,
                errorCode: errorCodes.user.PATCH_USER_PROFILE_FAILED
            };
        }
        case RESUME_TRAINER: {
            window.dispatch2background(setSnoozeTimer(0));

            return state;
        }
        case SET_INFINITY_SNOOZE: {
            window.dispatch2background(setSnoozeTimer(INFINITY_SNOOZE_SEC));

            return state;
        }
        case SNOOZE_TRAINER:{
            const { snoozeFor } = action.payload;

            if (state.isTrainerActive) {
                window.dispatch2background({
                    type: snoozeTypes.SNOOZE_TRAINER,
                    payload: { snoozeFor }
                });
            }

            return {
                ...state,
                errorCode: initialState.errorCode
            };
        }
        case ADD_SNOOZE_TIMER: {
            const { snoozeFor } = action.payload;
            const currentSnoozeTimer = state.snoozeTimer;

            window.dispatch2background(setSnoozeTimer(snoozeFor + currentSnoozeTimer));

            return {
                ...state,
                errorCode: initialState.errorCode
            };
        }
        case SET_MANUAL_BUTTON_AVAILABILITY: {
            const { payload } = action;

            return {
                ...state,
                isManualCallButtonEnabled: payload.isAvailable
            };
        }
        case SET_RECORD_CALL: {
            const { payload } = action;

            return {
                ...state,
                recordCall: payload.recordCall
            };
        }
        case DEVICE_LIST_RECEIVE_ERROR: {
            return {
                ...state,
                deviceListError: true
            };
        }

        case DEVICE_LIST_RECEIVED: {
            const { payload } = action;
            return {
                ...state,
                deviceList: {
                    mics: payload.mics,
                    speakers: payload.speakers
                }
            };
        }
        case MANUAL_OPT_IN: {
            window.dispatch2background({ type: types.OPT_IN });

            return {
                ...state,
                optInOn: true,
                isOptOut: false,
                stopRecordingCustomer: false,
            };
        }
        case MANUAL_OPT_STOP: {
            window.dispatch2background({ type: types.OPT_STOP });

            return {
                ...state,
                optInOn: false,
                isOptOut: false,
                stopRecordingCustomer: true,
            };
        }
        case MANUAL_OPT_OUT: {
            window.dispatch2background({ type: types.OPT_OUT });

            return {
                ...state,
                optInOn: false,
                isOptOut: true,
                stopRecordingCustomer: true,
            };
        }

        case EVENT_OPT_IN: {
            return {
                ...state,
                optInOn: true,
                isOptOut: false,
                stopRecordingCustomer: false,
            };
        }
        case EVENT_OPT_OUT: {
            return {
                ...state,
                optInOn: false,
                isOptOut: true,
                stopRecordingCustomer: true,
            };
        }
        case EVENT_OPT_STOP: {
            return {
                ...state,
                optInOn: false,
                isOptOut: false,
                stopRecordingCustomer: true,
            };
        }

        case OPT_RESET: {
            return {
                ...state,
                optInOn: true,
                isOptOut: false,
                stopRecordingCustomer: false,
            };
        }

        case RECEIVE_CRX_CALL_STARTED: {
            const {payload} = action;

            return {
                ...state,
                callId: payload.callId
            };
        }

        case SAVE_CONVERSION: {
            const { payload } = action;
            window.dispatch2background({ type: types.SAVE_CONVERSION, payload });

            return state;
        }

        case CALL_HOLD: {
            return {
                ...state,
                callHold: true
            };
        }
        case CALL_UNHOLD: {
            return {
                ...state,
                callHold: false
            };
        }
        case SEND_MANUAL_CALL_EVENT: {
            window.dispatch2background({
                type: types.SEND_MANUAL_CALL_EVENT,
                payload: {isStart: action.payload.isStart}
            });

            return state;
        }
        case CALL_NOTIFICATION: {
            const { payload } = action;
            if(payload.remove){
                return {
                    ...state,
                    callEventNotification: null,
                }
            }

            if(payload.eventName && !state.callEventNotification){
                return {
                    ...state,
                    callEventNotification: payload.eventName,
                }
            }

            return state;
        }

        default: {
            return state;
        }
    }
};

export default trainer;
