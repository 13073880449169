import {getUserInterfaceConfig, isConversionModalOpen} from "../selectors/trainer";
import {RECEIVE_CRX_CALL_ENDED, RECEIVE_CRX_CALL_STARTED} from "../actions/actionTypes/CRX";
import { SHOW_CONVERSION_MODAL, HIDE_CONVERSION_MODAL} from "../actions/actionTypes/conversion";
import {RELOAD, SAVE_CONVERSION} from "../actions/actionTypes/trainer";
import {CONVERSION_RESULT} from "../../components/Trainer/Conversion";
import {DISPLAY_CONVERSION_MODAL, isFeatureEnabled} from "../../config/features";

const SHORT_CALL_DURATION_SECONDS = 20;
const conversionMiddleware = store => next => action => {
    const { type, payload } = action;
    const state = store.getState();

    switch (type) {
        case RELOAD: {
            if(isFeatureEnabled(getUserInterfaceConfig(state), DISPLAY_CONVERSION_MODAL)
                && isConversionModalOpen(state)
                && !payload.code // if there is no error
            ) {
                store.dispatch({
                    type: SAVE_CONVERSION,
                    payload: {
                        conversionResult: CONVERSION_RESULT.IGNORED,
                        userId: String(state.user.userId),
                        callId: state.trainer.callId
                    }
                })
            }
            break;
        }
        case RECEIVE_CRX_CALL_ENDED: {
            if(isFeatureEnabled(getUserInterfaceConfig(state), DISPLAY_CONVERSION_MODAL)) {
                if (action.payload.callDuration > SHORT_CALL_DURATION_SECONDS) {
                    store.dispatch({
                        type: SHOW_CONVERSION_MODAL
                    })
                } else {
                    setTimeout(() => store.dispatch({
                        type: SAVE_CONVERSION,
                        payload: {
                            conversionResult: CONVERSION_RESULT.SHORT_CALL,
                            userId: String(state.user.userId),
                            callId: state.trainer.callId
                        }
                    }), 250); // a short pause for the call to get into the database
                }
            }
            break;
        }
        case RECEIVE_CRX_CALL_STARTED: {
            if(isFeatureEnabled(getUserInterfaceConfig(state), DISPLAY_CONVERSION_MODAL)) {
                if(isConversionModalOpen(state)){
                    store.dispatch({
                        type: SAVE_CONVERSION,
                        payload: {
                            conversionResult: CONVERSION_RESULT.OVERWRITTEN,
                            userId: String(state.user.userId),
                            callId: state.trainer.callId
                        }
                    })
                }
                store.dispatch({
                    type: HIDE_CONVERSION_MODAL
                })
            }
            break;
        }

        default:
    }

    return next(action);
}

export default conversionMiddleware