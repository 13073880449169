import styled from 'styled-components';

import { colors } from '../../../utils';

export default styled.div`
    color: ${colors.white.basic};
    font-size: 16px;
    font-weight: 600;
    padding: 14px 8px;
    border-radius: 4px;
    background-color: ${colors.green.flashygreen};
    display: inline;
    min-width: 190px;
    margin-top: 32px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background-color: ${colors.green.aquamarine};
    }
`;
