import {size} from 'lodash';
import ChunkBuffer from './ChunkBuffer';
import {
    asrConnectionClosed,
    asrVendorConfigError,
    recognitionReady,
    updateMicAsrResults,
    updateMicAsrSpeechPace,
    updateMicAsrVoiceMetrics,
    updateMicPhraseSpottingResults,
    updateSessionDurationMsec,
    updateSpeakerAsrResults,
    updateSpeakerAsrVoiceMetrics
} from '../store/actions/asr';
import {recLogger} from '../../utils/logger';
import {proto as vibeProto} from './AsrProto';
import AsrConnection from './asr/AsrConnection';
import {
    CALL_NOTIFICATION_DURATION,
    CALL_NOTIFICATION_END_PHRASE,
    CALL_NOTIFICATION_RESTART_PHRASE,
    CALL_NOTIFICATION_START_PHRASE,
    CALL_NOTIFICATION_TIMOUT,
    CALL_TIMER_UPDATE_INTERVAL,
    CallMode,
    CHANNEL,
    DEFAULT_CHUNK_BUFFER,
    EXPLICIT_INTEGRATION_SOURCE,
    KEYPHRASE_DETECTION_SILENCE_TIMOUT,
    KEYPHRASE_GROUP_NAME_FOR_START,
    KEYPHRASE_GROUP_NAME_FOR_STOP,
    RECORDER_ERROR_RELOAD_TIMEOUT,
    wsConnectionClosingCodes
} from '../constants';
import {
    callDetectionStarted,
    callDetectionStopped,
    callEndReceived,
    callFeedingStarted,
    callHolded,
    callNotification,
    callStartReceived,
    callUnholded,
    resetSessionDuration,
    types as callTypes,
    utcPingTimeout,
} from '../store/actions/call';
import {guid} from '../utils';
import {checkForBigerThanInt32} from '../utils/general';
import {IS_ELECTRON_APP_WITH_RECORDING, IS_LEGACY_LISTENER_MODE, IS_UI_ONLY_MODE} from "../../config/electron";
import {audioChunkReceived} from "../store/actions/device";
import {proto as listenerProto} from "./ListenerProto";
import {reload} from "../store/actions/common";

export default class AsrConnectionManager {
    constructor({ dispatch }) {
        this._dispatch = dispatch;

        this._config = null;
        this._recognitionConfig = null;

        this._asrConnection = null;
        this._callStartedAt = 0;

        this._recognitionUseSystem = true;

        this._callMode = CallMode.UNKNOWN;
        this._shouldSendSystemSound = false;
        this._shouldSendMicSound = false;
        this._feedStarted = 0;
        this._feedTimer = 0;
        this._micTimestamp = 0;
        this._sysTimestamp = 0;

        this._firstSysChunkTimestamp = null;
        this._firstMicChunkTimestamp = null;

        this._speakerBuffer = new ChunkBuffer(DEFAULT_CHUNK_BUFFER);
        this._micBuffer = new ChunkBuffer(DEFAULT_CHUNK_BUFFER);

        this._forwardedEvents = [];
        this._iamAliveInterval = null;

        this._pingCrashTimer = null;
        this._explicitPingPeriodMsec = 0;

        this._integrationWithKeyphrase = false;

        this._asrInBackgroundListening = false;
    }

    isCallStarted(){
        return !!this._callStartedAt;
    }

    _startPhraseDetected(){
        // mark that START phrase detected
        this._micBuffer.markAllUnSend();
        this._speakerBuffer.markAllUnSend();
    }

    _start(_asrInBackgroundListening) {
        const config = this._config;
        const { sampleRateHertz, ...restConfig } = config;

        this._callStartedAt = 0;
        this._asrInBackgroundListening = _asrInBackgroundListening;

        this._asrConnection = new AsrConnection('VibeConnection');

        this._asrConnection.on('stopped', params => {
            recLogger.log(`Vibe connection stopped`, params);

            if(params.code !== wsConnectionClosingCodes.RESTART) {
                this._dispatch(asrConnectionClosed(params));
                this._dispatch(callDetectionStopped(params.code));
            }

            if(!this._integrationWithKeyphrase && !this._integrationWithRecorderEvents) {
                this.stopElectronRecording();
            }
        });

        this._asrConnection.on('asr_vendor_config_error', () => {
            this._dispatch(asrVendorConfigError())
        });

        if(_asrInBackgroundListening){
            // in this case we want to listen for start keyword
            recLogger.log("Key phrase based call connection detection");

            this._asrConnection.on('started', ({id}) => {
                this.startIamAlive();
                this.flushForwardedEvents();

                this._asrConnection.sendRecognitionConfig({
                    callID: guid(),
                    sessionId: id,
                    ...this._recognitionConfig,
                });

                // we do not record the stream that we use to recognize the start
                this.sendChannelControlRequest(CHANNEL.MICROPHONE, true, true)
                this.sendChannelControlRequest(CHANNEL.SYSTEM, true, true)

                this._dispatch(callDetectionStarted(id));
            });

            this._asrConnection.on('phrase_spotting_result', data => {
                if(data.whitelist && !!data.whitelist.find(p => {
                    return p.groupName === KEYPHRASE_GROUP_NAME_FOR_START
                })){
                    recLogger.log('Call start phrase detected');

                    this._startPhraseDetected();
                    this._dispatch(callNotification(CALL_NOTIFICATION_START_PHRASE));

                    this._dispatch({
                        type: callTypes.CALL_RESTART,
                        payload: {
                            meta: {}
                        }
                    })
                }
            });

            this._asrConnection.start({
                sampleRateHertz,
                incallRecognitionSessionNumber: 0,
                ...restConfig,
            });
        } else {
            this._asrConnection.on('started', ({id}) => {
                this.startIamAlive();
                this.flushForwardedEvents();
                this._dispatch(callDetectionStarted(id));
            });

            if (IS_UI_ONLY_MODE) {
                this._asrConnection.on('call_start', ({callID}) => {
                    this.callStarted(callID);
                });
            }
            this._asrConnection.on('call_hold', this.emitCallHeld);
            this._asrConnection.on('call_unhold', this.emitCallUnheld);
            this._asrConnection.on('voice_metrics', data => {
                this.callStarted();

                if (data.channel === CHANNEL.MICROPHONE) {
                    this._dispatch(updateMicAsrVoiceMetrics(data));

                    if(this._integrationWithKeyphrase){
                        this._checkVoiceMetricsCall(data);
                    }
                } else {
                    this._dispatch(updateSpeakerAsrVoiceMetrics(data));
                }
            });
            this._asrConnection.on('speech_pace', data => {
                this.callStarted();

                this._dispatch(updateMicAsrSpeechPace(data));
            });
            this._asrConnection.on('asr_result', data => {
                this.callStarted();

                if (data.channel === CHANNEL.MICROPHONE) {
                    this._dispatch(updateMicAsrResults(data));
                } else {
                    this._dispatch(updateSpeakerAsrResults(data));
                }
            });
            this._asrConnection.on('phrase_spotting_result', data => {
                this.callStarted();

                if(this._integrationWithKeyphrase && data.whitelist && !!data.whitelist.find(p => {
                    return p.groupName === KEYPHRASE_GROUP_NAME_FOR_START
                })) {
                    // We skip the start phrase in a call we just started
                    if(this._micTimestamp > this._micBuffer.getOptInGracePeriodMsec() * 2){
                        recLogger.log('Call start phrase detected');

                        this._dispatch(callNotification(CALL_NOTIFICATION_RESTART_PHRASE));

                        this._dispatch({
                            type: callTypes.CALL_RESTART,
                            payload: {
                                meta: {}
                            }
                        });
                    }
                } else if(this._integrationWithKeyphrase && data.whitelist && !!data.whitelist.find(p => {
                    return p.groupName === KEYPHRASE_GROUP_NAME_FOR_STOP
                })){
                    recLogger.log('Call end phrase detected');
                    this._dispatch(callNotification(CALL_NOTIFICATION_END_PHRASE));

                    this._dispatch({type: callTypes.CALL_END});
                } else {
                    this._dispatch(updateMicPhraseSpottingResults(data));
                }
            });

            this._asrConnection.start({
                sampleRateHertz,
                incallRecognitionSessionNumber: 0,
                ...restConfig
            });
        }
    }

    callStarted(callID = null){
        this.emitCallStartEvent({
            callID,
            sessionOffsetMsec: 0,
        });
    }

    feedingStarted(){
        if(!this._feedStarted && this.isCallStarted()){
            this._feedStarted = Date.now();

            this._feedTimer = setInterval(() => {
                const diff = this._feedStarted > 0
                    ? (Date.now() - this._feedStarted)
                    : 0;

                this._dispatch(updateSessionDurationMsec(diff))
            }, CALL_TIMER_UPDATE_INTERVAL);

            this._dispatch(callFeedingStarted())
        }
    }

    sendForgetAudio(){
        this.sendChannelControlRequest(CHANNEL.MICROPHONE, false, true)
        this.sendChannelControlRequest(CHANNEL.SYSTEM, false, true)
    }

    setCallMode(mode, sendChannelControlRequest = true, forgetAudioAndTranscript = false){
        if(!this.isCallStarted()){
            return;
        }

        if(this._callMode === CallMode.NONE){
            return;
        }

        recLogger.log(`call mode changed:: [${this._shouldSendSystemSound ? '_shouldSendSystemSound' : ''}, ${this._shouldSendMicSound ? '_shouldSendMicSound' : ''}]`);

        switch (mode) {
            case CallMode.BOTH:
                recLogger.log(`call mode changed:: BOTH`);

                this._callMode = CallMode.BOTH;
                this._shouldSendSystemSound = true;
                this._shouldSendMicSound = true;

                this.feedingStarted();

                break;
            case CallMode.AGENT:
                recLogger.log(`call mode changed:: AGENT`);

                this._callMode = CallMode.AGENT;
                this._shouldSendSystemSound = false;
                this._shouldSendMicSound = true;

                this.feedingStarted();

                if(sendChannelControlRequest && forgetAudioAndTranscript) {
                    this.sendChannelControlRequest(CHANNEL.SYSTEM) // sys
                }
                break;
            case CallMode.CUSTOMER:
                recLogger.log(`call mode changed:: CUSTOMER`);

                this._callMode = CallMode.CUSTOMER;
                this._shouldSendSystemSound = true;
                this._shouldSendMicSound = false;

                this.feedingStarted();

                if(sendChannelControlRequest && forgetAudioAndTranscript) {
                    this.sendChannelControlRequest(CHANNEL.MICROPHONE) // mic
                }
                break;
            case CallMode.META:
                recLogger.log(`call mode changed:: META`);

                this._callMode = CallMode.META;
                this._shouldSendSystemSound = false;
                this._shouldSendMicSound = false;

                if(sendChannelControlRequest) {
                    this.sendChannelControlRequest(CHANNEL.MICROPHONE, false) // mic
                    this.sendChannelControlRequest(CHANNEL.SYSTEM, false) // sys
                }
                break;
            case CallMode.NONE:
                recLogger.log(`call mode changed:: NONE`);

                this._callMode = CallMode.NONE;
                this._shouldSendSystemSound = false;
                this._shouldSendMicSound = false;

                if(sendChannelControlRequest) {
                    this.sendChannelControlRequest(CHANNEL.MICROPHONE, true) // mic
                    this.sendChannelControlRequest(CHANNEL.SYSTEM, true) // sys
                }
                break;
            case CallMode.UNKNOWN:
                recLogger.log(`call mode changed:: UNKNOWN (reset)`);

                this._callMode = CallMode.UNKNOWN;
                this._shouldSendSystemSound = false;
                this._shouldSendMicSound = false;

                break;
            default:
        }
    }

    /**
     * @param channel - AGENT = 1; CUSTOMER = 2;
     * @param forgetAllData true/false
     * @param forgetAudioOnly true/false
     */
    sendChannelControlRequest(channel, forgetAllData = false, forgetAudioOnly = false){
        if (this.isConnectionOpen()) {
            this._asrConnection.sendChannelControlRequest(channel, forgetAllData, forgetAudioOnly);
        }
    }

    /**
     *
     * @param sessionOffsetMsec // timestamp of last received audio signal
     * @param callID // uuid of call
     * @param meta some info from telephony system
     */
    emitCallStartEvent = ({sessionOffsetMsec, callID, meta}) => {
        if (this.isCallStarted()) return;

        if(this._asrInBackgroundListening){
            // restart Vibe connection & start call
            this._restartConnection(false).then(() => {
                this.emitCallStartEvent({sessionOffsetMsec, callID, meta})
            });
            return;
        }

        const id = callID || guid();
        recLogger.log(`call started:: ${id}`);

        this._dispatch(callStartReceived({
            sessionOffsetMsec,
            id,
            metaCallID: meta?.call_id || null,
        }));

        if(meta){
            this._asrConnection.sendMeta(meta)
        }

        if(this._explicitPingPeriodMsec > 0){
            this._pingCrashTimer = setTimeout(this.pingCrash.bind(this), this._explicitPingPeriodMsec);
        }
    }

    emitCallEndEvent = ({ sessionOffsetMsec, reason }) => {
        this._dispatch(callEndReceived({ sessionOffsetMsec, reason }));

        if (this._pingCrashTimer) {
            clearTimeout(this._pingCrashTimer)
            this._pingCrashTimer = null;
        }
    }

    emitCallHeld = () => this._dispatch(callHolded());

    emitCallUnheld = () => this._dispatch(callUnholded());

    start(config, recognitionConfig) {
        this._config = config;
        this._recognitionConfig = recognitionConfig;

        window._enableRecorder = config.enableRecorder;

        this._recognitionUseSystem = config.recognitionUseSystem;
        this._explicitPingPeriodMsec = config.callDetection.explicitPingPeriodMsec
        this._integrationWithKeyphrase = config.callDetection.explicitIntegrationSource === EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT;
        this._integrationWithRecorderEvents = config.callDetection.explicitIntegrationSource === EXPLICIT_INTEGRATION_SOURCE.RECORDER_EVENT;
        this._appWhitelist = config.callDetection.appWhitelist || [];

        if(this._integrationWithKeyphrase || this._integrationWithRecorderEvents){
            this.startElectronRecording();
        }

        const optInGracePeriodMsec = config.callDetection.optInGracePeriodMsec || 0;

        this._speakerBuffer.setOptInGracePeriodMsec(optInGracePeriodMsec);
        this._micBuffer.setOptInGracePeriodMsec(optInGracePeriodMsec);

        const _asrInBackgroundListening = this._integrationWithKeyphrase;
        this._start(_asrInBackgroundListening);
    }

    startCall(recognitionConfig) {
        this._recognitionConfig = recognitionConfig;

        /**
         * If we have a buffer size, lets override the buffers with a new one
         * - Usecase: On not opt-in calls we want custom ms buffers that comes from
         *            the configuration.
         */
        this.stopIamAlive();

        if(IS_LEGACY_LISTENER_MODE || IS_ELECTRON_APP_WITH_RECORDING) {
            this._asrConnection.sendRecognitionConfig({
                ...recognitionConfig,
            });
        }

        this._dispatch(recognitionReady());

        if(!this._integrationWithRecorderEvents && !this._integrationWithKeyphrase) {
            this.startElectronRecording();
        }

        if(!this._integrationWithKeyphrase) {
            this.resetBuffer();
        }

        this._callStartedAt = Date.now();

        if(!this._asrInBackgroundListening) {
            this.feedingStarted();
        }
    }

    resetCallMode(){
        this._callMode = CallMode.UNKNOWN;
        this._shouldSendSystemSound = false;
        this._shouldSendMicSound = false;
        this._feedStarted = 0;

        localStorage.removeItem('wasOptInPressed');

        if(this._feedTimer) clearInterval(this._feedTimer)
    }

    stop({
        acdSessionOffsetMsec,
        asrSessionOffsetMsec,
        reason,
        code,
    }) {
        recLogger.log('CALL DURATION:', asrSessionOffsetMsec);
        recLogger.log('CALL END REASON:', reason);
        recLogger.log(
            'Speaker Buffer size on call end send/all:',
            `${this._speakerBuffer.countOfSend()}/${this._speakerBuffer.size()}`
        );
        recLogger.log(
            'Mic Buffer size on call end send/all:',
            `${this._micBuffer.countOfSend()}/${this._micBuffer.size()}`
        );

        this.resetBuffer();
        this.resetCallMode();

        if (this.isConnectionOpen()) {
            this._forwardedEvents = [];
            this._asrConnection.stop({
                acdSessionOffsetMsec: checkForBigerThanInt32(acdSessionOffsetMsec) ? 0 : acdSessionOffsetMsec,
                asrSessionOffsetMsec: checkForBigerThanInt32(asrSessionOffsetMsec) ? 0 : asrSessionOffsetMsec,
                reason,
                code,
            });
            this._asrConnection = null;
        }

        this._callStartedAt = 0;
    }

    async _restartConnection(_asrInBackgroundListening = false){
        recLogger.log('ASR Connection Restart');
        if (this.isConnectionOpen()) {
            this._forwardedEvents = [];
            await new Promise(stopped => {
                this._asrConnection.on('stopped', () => {
                    stopped();
                })
                this._asrConnection.stop({
                    acdSessionOffsetMsec: 0,
                    asrSessionOffsetMsec: 0,
                    reason: vibeProto.FinalizationReason.CALL_END_NORMAL,
                    code: wsConnectionClosingCodes.RESTART,
                });
            })
        }
        recLogger.log('ASR Connection Stopped');
        await new Promise(resolve => {
            this._asrConnection = null;
            this._start(_asrInBackgroundListening);
            this._asrConnection.on('started', () => {
                resolve();
            });
        })
    }

    _checkVoiceMetricsCall(metrics){
        if(!metrics.ourSideIsSpeaking){
            if(!this._keyphraseDetectionSilenceTimout){
                this._keyphraseDetectionSilenceTimout = setTimeout(() => this._dispatch({type: callTypes.CALL_END}), KEYPHRASE_DETECTION_SILENCE_TIMOUT)
                this._keyphraseDetectionSilenceNotificationTimout = setTimeout(() => this._dispatch(callNotification(CALL_NOTIFICATION_TIMOUT)), KEYPHRASE_DETECTION_SILENCE_TIMOUT - CALL_NOTIFICATION_DURATION)
            }
        } else {
            clearTimeout(this._keyphraseDetectionSilenceTimout)
            this._keyphraseDetectionSilenceTimout = undefined;
            clearTimeout(this._keyphraseDetectionSilenceNotificationTimout)
        }
    }

    isConnectionOpen() {
        return this._asrConnection && this._asrConnection.isOpened();
    }

    enqueueChunks({ audioChunk }) {
        if(audioChunk.channel === listenerProto.DataFlow.CAPTURE){
            this._micBuffer.enqueue(audioChunk);
        } else {
            this._speakerBuffer.enqueue(audioChunk);
        }
    }

    timestampToRtpTimestamp(timestamp){
        return Math.round((window.hasOpus || IS_ELECTRON_APP_WITH_RECORDING) ? (
            // listner OPUS 48000 / (1000/240) = 11520
            48 * timestamp
        ) : (
            // mock WAV: 16000 / (1000/240) = 3840
            16 * timestamp
        ));
    }

    rtpTimestampToTimestamp(timestamp){
        return Math.round((window.hasOpus || IS_ELECTRON_APP_WITH_RECORDING) ? (
            // listner OPUS 48000 / (1000/240) = 11520
            timestamp / 48
        ) : (
            // mock WAV: 16000 / (1000/240) = 3840
            timestamp / 16
        ));
    }

    markAllUnSend(){
        this._micBuffer.markAllUnSend();
        this._speakerBuffer.markAllUnSend();
    }

    isChunkProcessingAllowed(){
        if (!this.isConnectionOpen() || (!IS_LEGACY_LISTENER_MODE && !IS_ELECTRON_APP_WITH_RECORDING)){
            return false;
        }

        if(!this.isCallStarted() && !(this._integrationWithKeyphrase && this._asrInBackgroundListening)) {
            return false;
        }

        return true;
    }

    isMicChunkProcessingAllowed(){
        return this._shouldSendMicSound || this._integrationWithKeyphrase;
    }

    isSysChunkProcessingAllowed(){
        return this._shouldSendSystemSound && this._recognitionUseSystem;
    }

    processChunk() {
        if(!this.isChunkProcessingAllowed()){
            return;
        }

        if (this.isMicChunkProcessingAllowed()) {
            const micChunk = this._micBuffer.mergeToOne();
            if(micChunk && micChunk.audioChunk.length) {
                let micTimestamp = micChunk.offsetInAudioStreamMsec;
                if(this._firstMicChunkTimestamp === null && this.isCallStarted()){
                    this._firstMicChunkTimestamp = this._firstSysChunkTimestamp || micTimestamp;
                }

                micTimestamp -= this._firstMicChunkTimestamp;
                if(micTimestamp < 0) micTimestamp = 0;

                this._micTimestamp = micTimestamp;

                this._asrConnection.processChunk({
                    ...micChunk,
                    timestamp: this.timestampToRtpTimestamp(micTimestamp),
                });
            }
        }

        if (this.isSysChunkProcessingAllowed()) {
            const speakerChunk = this._speakerBuffer.mergeToOne();
            if(speakerChunk && speakerChunk.audioChunk.length) {
                let systemTimestamp = speakerChunk.offsetInAudioStreamMsec;
                if(this._firstSysChunkTimestamp === null && this.isCallStarted()){
                    this._firstSysChunkTimestamp = this._firstMicChunkTimestamp || systemTimestamp;
                }

                systemTimestamp -= this._firstSysChunkTimestamp;
                if(systemTimestamp < 0) systemTimestamp = 0;

                this._sysTimestamp = systemTimestamp;

                this._asrConnection.processChunk({
                    ...speakerChunk,
                    timestamp: this.timestampToRtpTimestamp(systemTimestamp),
                });
            }
        }
    }

    sendPing() {
        if (this.isConnectionOpen() && (IS_LEGACY_LISTENER_MODE || IS_ELECTRON_APP_WITH_RECORDING)) {
            // this._asrConnection.sendPing(); // @todo
        }
    }

    startIamAlive() {
        this._iamAliveInterval = setInterval(() => {
            this.sendPing()
        }, 5000);
    }

    stopIamAlive() {
        clearInterval(this._iamAliveInterval);
    }

    resetBuffer(resetInitialTimestamps = true) {
        this._speakerBuffer.reset();
        this._micBuffer.reset();

        if(resetInitialTimestamps) {
            this._firstSysChunkTimestamp = null;
            this._firstMicChunkTimestamp = null;
        }
    }

    flushForwardedEvents() {
        while (size(this._forwardedEvents) > 0) {
            const event = this._forwardedEvents.shift();
            this._asrConnection.sendForwardedEvent(event);
        }
    }

    forwardEvent(event) {
        this._forwardedEvents.push(event);
        if (this.isConnectionOpen()) {
            this.flushForwardedEvents();
        }
    }

    utcPing() {
        if (
            this._explicitPingPeriodMsec > 0 &&
            this._pingCrashTimer
        ) {
            clearTimeout(this._pingCrashTimer);
            this._pingCrashTimer = setTimeout(this.pingCrash.bind(this), this._explicitPingPeriodMsec)
        }
    }

    pingCrash(){
        this._dispatch(utcPingTimeout());
    }

    startElectronRecording(){
        this.stopElectronRecording();
        if(IS_ELECTRON_APP_WITH_RECORDING && window.electronAPI){
            window.electronAPI.startRecording({
                recordSysAudio: this._recognitionUseSystem,
                useEvents: this._integrationWithRecorderEvents,
                appWhitelist: this._appWhitelist,
            });
            window.electronAPI.onRecorderChunk((chunk) => {
                const audioChunk = vibeProto.AudioChunkWithMetadata.decode(chunk);
                const chunkTimestamp = this.rtpTimestampToTimestamp(audioChunk.timestamp)

                if(
                    audioChunk.channel === listenerProto.DataFlow.CAPTURE && this.isMicChunkProcessingAllowed()
                ){
                    this._dispatch(audioChunkReceived({
                        audio: audioChunk.audioChunk,
                        channel: audioChunk.channel,
                        offsetInAudioStreamMsec: chunkTimestamp,
                    }))
                }

                if(
                    audioChunk.channel === listenerProto.DataFlow.RENDER && this.isSysChunkProcessingAllowed()
                ) {
                    this._dispatch(audioChunkReceived({
                        audio: audioChunk.audioChunk,
                        channel: audioChunk.channel,
                        offsetInAudioStreamMsec: chunkTimestamp,
                    }))
                }
            })
            window.electronAPI.onRecorderStopped(() => {
                if(this._integrationWithKeyphrase || this._integrationWithRecorderEvents || this.isCallStarted()){
                    setTimeout(() => this._dispatch(reload({
                        reason: 'RECORDER_EXITED'
                    })), RECORDER_ERROR_RELOAD_TIMEOUT)
                }
            });
        }
    }

    stopElectronRecording(){
        if(IS_ELECTRON_APP_WITH_RECORDING && window.electronAPI?.stopRecording){
            window.electronAPI.stopRecording();

            this._dispatch(resetSessionDuration());
        }
    }
}
