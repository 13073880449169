import styled from 'styled-components';
import CardWrapper from './CardWrapper';
import Wrapper from './Wrapper';
import UserInfoWrapper from './UserInfoWrapper';
import Notification, {NotificationLight} from './Notification';
import { ReloadButton } from './ReloadButton';
import { colors } from '../../../utils';

export const Container = styled.div`
    position: relative;
    margin-top: 8px;
    width: 300px;
    align-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 #cdd3df;
    border-radius: 4px;
    background: white;
    padding: 8px;
`;

export const TabMenu = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 12px;
    width: 100%;
    border-bottom: 1px solid #F5F5F5;
`;

export const Tab = styled.div`
    font-size: 14px;
    font-weight: bold;
    width: 32%;
    text-align: center;
    padding: 10px 5px 0 5px;
    color: ${props => {
        console.info('p', props)
        if(props.$disabled){
            return colors.gray.jumbo;
        }
        if(props.$active){
            return '#5d5ff9'
        }
        return '#333'
    }};
    cursor: pointer;
    
    &::after {
        content: '';
        display: block;
        margin: 4px auto 0 auto;
        width: 28px;
        border-radius: 4px 4px 0 0;
        height: 3px;
        background: ${props => (props.$active ? '#5d5ff9' : 'none')};
    }
`;

export const Separator = styled.hr`
    border: none;
    border-top: 1px solid #E8E8E8;
`;


export { CardWrapper, Wrapper, UserInfoWrapper, Notification, NotificationLight, ReloadButton };
