import productsO2telefonica from './products_O2_Telefonica.json'

const LOCATION_TELEFONICA = 303;
const PROD_QA_LOCATION_SALES = 353;
const DEV_LOCATION_QA = 72;
const DEV_LOCATION_QA_EXP_SALES = 4887;
const PROD_CHOTIME_TELEFONICA_KAVALA = 354;
const PROD_CHOTIME_TELEFONICA_THESSALONIKI = 355;

export const getSalesTrackingData = async ({locationId}) => {
    switch(locationId) {
        case PROD_QA_LOCATION_SALES:
        case DEV_LOCATION_QA:
        case DEV_LOCATION_QA_EXP_SALES:
        case PROD_CHOTIME_TELEFONICA_KAVALA:
        case PROD_CHOTIME_TELEFONICA_THESSALONIKI:
        case LOCATION_TELEFONICA:
            return productsO2telefonica.map((item) => ({
                product: item[2],
                category: item[1],
                campaign: item[0],
            }))
        default:
            return []
    }
}
