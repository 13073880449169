import {objectToSnakeCase} from '../../utils';
import {sendRequest} from './common';
import {REQUESTS, USER_EVENT_TYPE} from '../../constants';
import {startMainConnection} from "./main";
import {IS_LEGACY_LISTENER_MODE} from "../../../config/electron";
import {logUserEvent} from "./eventLogger";

export const types = {
    AUTH_FAIL: '@bg/AUTH_FAIL',
    AUTH_SUCCESS: '@bg/AUTH_SUCCESS',

    AUTH_WITH_PASSWORD_REQUIRED: '@bg/AUTH_WITH_PASSWORD_REQUIRED',

    TOKEN_REFRESH_SUCCESS: '@bg/TOKEN_REFRESH_SUCCESS',

    SYSTEM_INFO_RECEIVED: '@bg/SYSTEM_INFO_RECEIVED', // receive system info from listener
    SYSTEM_INFO_UPDATED: '@bg/SYSTEM_INFO_UPDATED',  // receive updated (may be same) info from listener
};

export const loginWithCompany = (
    locationName,
    username,
    companySecretToken,
    uniqueId, // @deprecated
) => dispatch => {
    const data = {
        companyName: locationName,
        username,
        token: companySecretToken,
        uniqueId,
    };
    const payload = {
        body: objectToSnakeCase(data)
    };

    const onSuccess = ({ token, refreshToken }) => {
        dispatch({
            type: types.AUTH_SUCCESS,
            payload: {
                token,
                refreshToken,
                isPasswordAuth: false,
            }
        });
    };

    const onError = error => {
        dispatch({
            type: types.AUTH_FAIL,
            payload: {
                status: error.status,
                message: error.message
            }
        });
    };

    dispatch(
        sendRequest(REQUESTS.COMPANY_LOGIN, {
            payload,
            onSuccess,
            onError
        })
    );
};

export const loginWithCompanyAndPassword = (
    locationAlias,
    username,
    password,
) => dispatch => {
    const payload = {
        body: {
            company_name: locationAlias,
            username,
            password,
        }
    };

    const onSuccess = ({ token, refreshToken }) => {
        dispatch({
            type: types.AUTH_SUCCESS,
            payload: {
                token,
                refreshToken,
                isPasswordAuth: true,
            }
        })
        dispatch(logUserEvent(USER_EVENT_TYPE.LOGIN))
    };

    const onError = error => {
        dispatch({
            type: types.AUTH_FAIL,
            payload: {
                status: error.status,
                message: error.message
            }
        })
    };

    dispatch(
        sendRequest(REQUESTS.COMPANY_LOGIN, {
            payload,
            onSuccess,
            onError,
        })
    );
};

export const doRefreshToken = (authStore, isLogin, dispatch) => {
    // if no token then start connection to linter
    if(!authStore.refreshToken && !IS_LEGACY_LISTENER_MODE){
        dispatch({
            type: types.AUTH_WITH_PASSWORD_REQUIRED
        })
        return;
    }

    if (IS_LEGACY_LISTENER_MODE && isLogin) {
        dispatch(startMainConnection());
        return;
    }

    const payload = {
        body: {
            refresh_token: authStore.refreshToken,
        }
    };

    const onSuccess = ({ token }) => {
        if(isLogin){
            dispatch({
                type: types.AUTH_SUCCESS,
                payload: {
                    token,
                    refreshToken: authStore.refreshToken,
                    isPasswordAuth: authStore.authWithPassword,
                }
            })
        } else if(IS_LEGACY_LISTENER_MODE) {
            dispatch({
                type: types.TOKEN_REFRESH_SUCCESS,
                payload: { token }
            })
        }
    };

    const onError = error => {
        dispatch({
            type: types.AUTH_FAIL,
            payload: {
                status: error.status,
                message: error.message
            }
        });
    };

    dispatch(
        sendRequest(REQUESTS.REFRESH_TOKEN, {
            payload,
            onSuccess,
            onError,
        })
    );
};
