import styled from "styled-components";
import {colors} from "../../../../utils";

export const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 4px;
    text-align: center;
    margin-top: 0;
    color: ${colors.gray.monsoon};
`;

export const Subtitle = styled.p`
    font-size: 16px;
    color: ${colors.gray.monsoon};
    margin-bottom: 16px;
    text-align: center;
    margin-top: 0;
`;

export const Separator = styled.hr`
    border: 0;
    border-bottom: 1px solid #ddd;
`

export const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    text-align: left;
    margin-bottom: 16px;
    font-size: ${props => props.$fontSize || '16px'};
`;

export const InfoLabel = styled.p`
    font-size: 0.8em;
    color: #999;
    margin: 5px 0;
`;

export const InfoValue = styled.p`
    font-size: 1em;
    color: #333;
    margin: 5px 0;
    word-break: break-all;
`;